import React from "react"
import { Link } from "gatsby"
import '../styles/homepage.css'
import '../styles/utilitary.css'
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import scrollTo from 'gatsby-plugin-smoothscroll';
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new.png" // Tell webpack this JS file uses this image

import card1 from "../images/card-filete-salmao2.png" // Tell webpack this JS file uses this image
import card2 from "../images/card-finissimos-salmao2.png" // Tell webpack this JS file uses this image
import card3 from "../images/card-gravlax2.png" // Tell webpack this JS file uses this image
import card4 from "../images/card-hamb-atum.png" // Tell webpack this JS file uses this image
import card5 from "../images/card-hamb-bac2.png" // Tell webpack this JS file uses this image
import card6 from "../images/card-hamb-salmao2.png" // Tell webpack this JS file uses this image
import card7 from "../images/card-posta-salmao2.png" // Tell webpack this JS file uses this image
import card8 from "../images/card-medalhoes.png" // Tell webpack this JS file uses this image
import card9 from "../images/card-porcao-bacalhau.png" // Tell webpack this JS file uses this image
import card10 from "../images/card-corvina2.png" // Tell webpack this JS file uses this image


import plusSymbol from "../images/plus-symbol.png" // Tell webpack this JS file uses this image







const IndexPage = () => (
  <Layout>
    <SEO title="Peixe Fácil" />
    <div className="wrapper-bg">
        <div className="left-col-bg">

        </div>
        <div className="right-col-bg">
          
        </div>
      </div>

    
    <div id="section-1">
  
      <div className="wrapper-container">
      <div className="right-col-mobile">
        
        </div>
        <div className="left-col">
        <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease"  className="mobile-logo">
            <img src={logo} alt="Logo" />
          </div>
        
        <div className="text-element">
            
        <div className="logo-brand">
        <img src={logonew} alt="" />

        </div>
          <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="titulo absara">
            <p>Nunca foi tão fácil<br></br>
              cozinhar.
            </p>
          </div>
          <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="descricao sneak-regular opacity">
          <p>Nesta plataforma poderá seguir receitas e 
modos de preparo para que tire o máximo 
partido da qualidade do alimento e da sua frescura.
            </p>
          </div>
          {/* <div className="btn-div">
            <button onClick={() => scrollTo('#section-2')} className="btn sneak-bold">Produtos</button>

          </div> */}
          </div>
        </div>
        <div className="right-col">
        </div>
      </div>
    </div>
    <div id ="container-cards">
      <div className="wrapper-cards">
      {/* <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="container-card4 card-dimension">
          <Link to="/hamburguer-atum/" style={{ textDecoration: 'none' }}><div className="card4 card">
          <div className="foto-container">
          <img src={card4} alt="" />

            </div>
            <div className="text-container">
              <div className="titulo absara blue-marine">
                Hambúrguer <br></br> de Atum

              </div>
              <div className="icone-plus">
              <img src={plusSymbol} alt="" />

              </div>
            </div>

</div>
</Link>
          </div> */}

          {/* <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="container-card5 card-dimension">
          <Link to="/hamburguer-bacalhau/" style={{ textDecoration: 'none' }}>
          <div className="card5 card">
          <div className="foto-container">
          <img src={card5} alt="" />

            </div>
            <div className="text-container">
              <div className="titulo absara blue-marine">
              Hambúrguer <br></br> de Bacalhau

              </div>
              <div className="icone-plus">
              <img src={plusSymbol} alt="" />

              </div>
            </div>

</div>
</Link>
          </div> */}

          {/* <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="container-card6 card-dimension">
          <Link to="/hamburguer-salmao-chili-lemon/" style={{ textDecoration: 'none' }}><div className="card6 card">
          <div className="foto-container">
          <img src={card6} alt="" />

            </div>
            <div className="text-container">
              <div className="titulo absara blue-marine">
              Hambúrguer <br></br> de Salmão


              </div>
              <div className="icone-plus">
              <img src={plusSymbol} alt="" />

              </div>
            </div>

</div></Link>
          </div> */}

        {/* <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="container-card1 card-dimension">
        <Link to="/filete-salmao/" style={{ textDecoration: 'none' }}>
          <div className="card1 card">
            <div className="foto-container">
            <img src={card1} alt="" />

            </div>
            <div className="text-container">
              <div className="titulo absara blue-marine">
                Filete <br></br> de Salmão

              </div>
              <div className="icone-plus">
              <img src={plusSymbol} alt="" />

              </div>
            </div>
          </div>
          </Link>

        </div> */}
        <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="container-card2 card-dimension">
        <Link to="/finissimos-salmao/" style={{ textDecoration: 'none' }}>
        <div className="card2 card">
        <div className="foto-container">
        <img src={card2} alt="" />

            </div>
            <div className="text-container">
              <div className="titulo absara blue-marine">
                Finíssimos <br></br> de Salmão

              </div>
              <div className="icone-plus">
              <img src={plusSymbol} alt="" />

                
              </div>
            </div>

</div>
</Link>
          </div>
          {/* <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="container-card3 card-dimension">
          <Link to="/gravlax-salmao/" style={{ textDecoration: 'none' }}>
          <div className="card3 card">
          <div className="foto-container">
          <img className="shadow" src={card3} alt="" />

            </div>
            <div className="text-container">
              <div className="titulo absara blue-marine">
                Gravlax <br></br> de Salmão

              </div>
              <div className="icone-plus">
              <img src={plusSymbol} alt="" />

              </div>
            </div>

</div>
</Link>
          </div> */}

       
       
          {/* <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="container-card7 card-dimension">
          <Link to="/posta-salmao/" style={{ textDecoration: 'none' }}>
          <div className="card7 card">
          <div className="foto-container">
          <img src={card7} alt="" />

            </div>
            <div className="text-container">
              <div className="titulo absara blue-marine">
                Posta <br></br> de Salmão

              </div>
              <div className="icone-plus">
              <img src={plusSymbol} alt="" />

              </div>
            </div>

</div>
</Link>
          </div> */}
          {/* <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="container-card7 card-dimension">
          <Link to="/medalhoes-salmao/" style={{ textDecoration: 'none' }}>
          <div className="card7 card">
          <div className="foto-container">
          <img src={card8} alt="" />

            </div>
            <div className="text-container">
              <div className="titulo absara blue-marine">
                Medalhões <br></br> de Salmão

              </div>
              <div className="icone-plus">
              <img src={plusSymbol} alt="" />

              </div>
            </div>

</div>
</Link>
          </div> */}
          {/* <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="container-card7 card-dimension">
          <Link to="/bacalhau/" style={{ textDecoration: 'none' }}>
          <div className="card7 card">
          <div className="foto-container">
          <img src={card9} alt="" />

            </div>
            <div className="text-container">
              <div className="titulo absara blue-marine">
                Porção <br></br> de Bacalhau

              </div>
              <div className="icone-plus">
              <img src={plusSymbol} alt="" />

              </div>
            </div>

</div>
</Link>
          </div> */}


          <div data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease" className="container-card7 card-dimension">
          <Link to="/corvina/" style={{ textDecoration: 'none' }}>
          <div className="card7 card">
          <div className="foto-container">
          <img src={card10} alt="" />

            </div>
            <div className="text-container">
              <div className="titulo absara blue-marine">
              <br></br> Corvina <br></br> 

              </div>
              <div className="icone-plus">
              <img src={plusSymbol} alt="" />

              </div>
            </div>

</div>
</Link>
          </div>

      </div>
    </div>
    {/* <div id="section-2">
      <div className="wrapper-container">
          <div className="col-1">
          <div className="conteudo">

          <Link to="/hamburguer-salmao-chili-lemon/"><button className="btn sneak-bold">Hambúrguer de Salmão Chili-Lemon</button></Link>
          </div>

          </div>
          <div className="col-2">
            <div className="conteudo">
            <Link to="/hamburguer-atum/"><button className="btn sneak-bold">Hambúrguer de Atum</button></Link>
          </div>
            </div>
            <div className="col-3">
            <div className="conteudo">

           <Link to="/hamburguer-bacalhau/"> <button className="btn sneak-bold">Hambúrguer de Bacalhau</button></Link>
            </div>
            </div>
          
      </div>
    </div> */}


  </Layout>
)

export default IndexPage
